.contact-section {
  padding: 120px 0;
  background: #0a192f;
  position: relative;
  overflow: hidden;
}

/* Add subtle background pattern */
.contact-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 50% 50%, rgba(100, 255, 218, 0.03) 0%, transparent 50%);
  pointer-events: none;
}

.contact-container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 150px;
  position: relative;
}

.contact-content {
  position: relative;
}

.contact-title {
  font-size: 2.5rem;
  color: #ccd6f6;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.contact-number {
  color: #64ffda;
  font-family: 'SF Mono', 'Fira Code', monospace;
  font-size: 1.5rem;
  font-weight: normal;
}

.contact-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  align-items: end;
}

.contact-info {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.contact-description {
  color: #8892b0;
  font-size: 1.2rem;
  line-height: 1.7;
  margin-bottom: 1.5rem;
  max-width: 500px;
}

.contact-details {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  margin-top: 2rem;
  width: 100%;
  max-width: 100%;
}

.contact-item {
  background: rgba(100, 255, 218, 0.02);
  border: 1px solid rgba(100, 255, 218, 0.1);
  border-radius: 16px;
  padding: 2rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  transition: transform 0.3s ease;
  width: 100%;
  box-sizing: border-box;
}

/* Add gradient border effect */
.contact-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, 
    transparent,
    rgba(100, 255, 218, 0.3),
    transparent
  );
}

.contact-item:hover {
  background: rgba(100, 255, 218, 0.05);
  transform: translateY(-5px);
  box-shadow: 0 20px 40px -20px rgba(100, 255, 218, 0.15);
}

.contact-icon {
  font-size: 2rem;
  color: #64ffda;
  background: rgba(100, 255, 218, 0.1);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.contact-item:hover .contact-icon {
  transform: scale(1.1);
  background: rgba(100, 255, 218, 0.15);
}

.contact-text h3 {
  color: #ccd6f6;
  font-size: 1.1rem;
  margin-bottom: 0.3rem;
  font-weight: 600;
}

.contact-text p {
  color: #8892b0;
  font-size: 1rem;
}

.contact-text {
  flex: 1;
  min-width: 0;
}

.contact-form-container {
  background: rgba(100, 255, 218, 0.02);
  border: 1px solid rgba(100, 255, 218, 0.1);
  border-radius: 20px;
  padding: 2.5rem;
  position: relative;
  overflow: hidden;
  margin-top: 0;
}

/* Add subtle gradient overlay */
.contact-form-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(100, 255, 218, 0.03) 0%,
    transparent 100%
  );
  pointer-events: none;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
}

.form-group {
  width: 100%;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 1.2rem 1.5rem;
  background: rgba(100, 255, 218, 0.02);
  border: 1px solid rgba(100, 255, 218, 0.1);
  border-radius: 12px;
  color: #ccd6f6;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: rgba(100, 255, 218, 0.3);
  background: rgba(100, 255, 218, 0.05);
  box-shadow: 0 0 0 4px rgba(100, 255, 218, 0.1);
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: #8892b0;
}

.form-group textarea {
  resize: vertical;
  min-height: 150px;
}

/* Submit button styling */
.contact-form .cta-button {
  margin-top: 1rem;
  width: 100%;
  justify-content: center;
}

@media (max-width: 1400px) {
  .contact-grid {
    gap: 4rem;
  }
}

@media (max-width: 1630px) {
  .contact-item {
    padding: 1.5rem;
  }

  .contact-text {
    flex: 1;
    min-width: 0;
  }

  .social-links {
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .social-link {
    width: 28px;
    height: 28px;
  }
}

@media (max-width: 1200px) {
  .contact-grid {
    grid-template-columns: 1fr;
    gap: 3rem;
    align-items: stretch;
  }
  
  .contact-description {
    max-width: 100%;
  }

  .contact-details {
    max-width: 100%;
  }

  .contact-form-container {
    margin-top: 0;
  }

  .contact-item {
    padding: 1.5rem;
  }

  .social-links {
    gap: 1rem;
  }
}

@media (max-width: 1024px) {
  .contact-container {
    padding: 0 24px;
  }
}

@media (max-width: 968px) {
  .contact-grid {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .contact-form {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .contact-section {
    padding: 80px 0;
  }

  .contact-container {
    padding: 0 24px;
  }

  .contact-title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  .contact-form-container {
    padding: 2rem;
  }

  .contact-item {
    flex-direction: column;
    text-align: center;
    padding: 1.5rem;
  }

  .contact-icon {
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
  }

  .contact-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .contact-info {
    text-align: center;
  }

  .contact-description {
    max-width: 600px;
    margin: 0 auto 2rem;
  }

  .contact-details {
    max-width: 100%;
  }

  .contact-item {
    padding: 1.5rem;
  }

  .contact-form-container {
    max-width: 100%;
    padding: 1.5rem;
  }

  .form-group input,
  .form-group textarea {
    padding: 12px;
    font-size: 1rem;
  }

  .contact-title {
    font-size: 2rem;
    text-align: center;
  }

  .social-link {
    width: 28px;
    height: 28px;
  }

  .contact-text {
    min-width: unset;
    width: 100%;
  }

  .social-links {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .contact-container {
    padding: 0 16px;
  }

  .contact-item {
    padding: 0.75rem;
  }

  .form-group input,
  .form-group textarea {
    padding: 10px;
  }

  .contact-form button {
    width: 100%;
  }
}

.social-links {
  display: flex;
  gap: 1.5rem;
  margin-top: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
}

.social-link {
  color: #8892b0;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: rgba(100, 255, 218, 0.05);
  border-radius: 8px;
  padding: 6px;
}

.social-link:hover {
  color: #64ffda;
  background: rgba(100, 255, 218, 0.1);
  transform: translateY(-2px);
}

.social-link svg {
  width: 100%;
  height: 100%;
}
