/* Modern Futuristic Button Styles */
.cta-button {
  position: relative;
  padding: 14px 32px;
  background: rgba(100, 255, 218, 0.03);
  border: 1px solid rgba(100, 255, 218, 0.3);
  color: #64ffda;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  white-space: nowrap;
  clip-path: polygon(
    92% 0,
    100% 25%,
    100% 100%,
    8% 100%,
    0 75%,
    0 0
  );
}

/* Glowing border effect */
.cta-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid transparent;
  background: linear-gradient(
    135deg,
    rgba(100, 255, 218, 0),
    rgba(100, 255, 218, 0.4),
    rgba(100, 255, 218, 0)
  );
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  transition: 0.5s;
}

.cta-button::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    135deg,
    rgba(100, 255, 218, 0.1),
    rgba(0, 188, 212, 0.1)
  );
  opacity: 0;
  transition: 0.3s;
}

/* Button content wrapper to ensure it's above the borders */
.button-content {
  position: relative;
  z-index: 2;
}

.cta-button .button-icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
  transition: transform 0.3s;
}

.cta-button .button-arrow {
  font-family: monospace;
  transition: transform 0.3s;
  margin-left: 4px;
  opacity: 0.8;
}

/* Primary Button */
.cta-button.primary {
  background: linear-gradient(
    135deg,
    rgba(100, 255, 218, 0.9),
    rgba(0, 188, 212, 0.9)
  );
  border: none;
  color: #0a192f;
  font-weight: 600;
}

/* Hover Effects */
.cta-button:hover {
  border-color: rgba(100, 255, 218, 0.5);
  transform: translateY(-2px);
  box-shadow: 
    0 7px 20px -3px rgba(100, 255, 218, 0.15),
    0 0 15px rgba(100, 255, 218, 0.1);
}

.cta-button:hover::before {
  border-color: rgba(100, 255, 218, 0.5);
}

.cta-button:hover::after {
  opacity: 1;
}

.cta-button:hover .button-icon {
  transform: scale(1.1) rotate(-5deg);
}

.cta-button:hover .button-arrow {
  transform: translateX(4px);
  opacity: 1;
}

.cta-button.primary:hover {
  background: linear-gradient(
    135deg,
    rgba(100, 255, 218, 1),
    rgba(0, 188, 212, 1)
  );
  box-shadow: 
    0 7px 20px -3px rgba(100, 255, 218, 0.25),
    0 0 20px rgba(100, 255, 218, 0.2);
}

/* Active State */
.cta-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 8px rgba(100, 255, 218, 0.15);
}

/* Focus State */
.cta-button:focus {
  outline: none;
  box-shadow: 
    0 0 0 2px rgba(100, 255, 218, 0.2),
    0 0 15px rgba(100, 255, 218, 0.15);
}

/* Disabled State */
.cta-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .cta-button {
    padding: 12px 28px;
    font-size: 13px;
  }
} 