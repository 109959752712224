.mission-section {
  padding: 60px 0;
  background: #0a192f;
  position: relative;
}

.mission-container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 150px;
}

.mission-content {
  position: relative;
}

.mission-title {
  font-size: 2.5rem;
  color: #ccd6f6;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.mission-number {
  color: #64ffda;
  font-family: 'SF Mono', 'Fira Code', monospace;
  font-size: 1.5rem;
  font-weight: normal;
}

.mission-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
}

.mission-text {
  position: relative;
}

.mission-description {
  color: #8892b0;
  font-size: 1.1rem;
  line-height: 1.7;
  margin-bottom: 1.5rem;
}

.mission-stats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(100, 255, 218, 0.1);
}

.stat {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.5rem;
  background: rgba(100, 255, 218, 0.05);
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.stat:hover {
  transform: translateY(-5px);
  background: rgba(100, 255, 218, 0.08);
}

.stat-highlight {
  color: #64ffda;
  font-size: 2.2rem;
  font-weight: 600;
}

.stat-label {
  color: #8892b0;
  font-size: 0.9rem;
  line-height: 1.4;
}

.mission-visual {
  position: relative;
}

.visual-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

.grid-item {
  background: rgba(100, 255, 218, 0.02);
  border: 1px solid rgba(100, 255, 218, 0.05);
  padding: 2.5rem 2rem;
  border-radius: 12px;
  color: #ccd6f6;
  text-align: center;
  transition: all 0.4s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  position: relative;
  overflow: hidden;
}

.grid-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(100, 255, 218, 0.2), transparent);
  opacity: 0;
  transition: opacity 0.4s ease;
}

.grid-item:hover {
  background: rgba(100, 255, 218, 0.03);
  transform: translateY(-3px);
}

.grid-item:hover::before {
  opacity: 1;
}

.grid-icon {
  width: 40px;
  height: 40px;
  color: rgba(100, 255, 218, 0.7);
  padding: 8px;
  background: rgba(100, 255, 218, 0.03);
  border-radius: 10px;
  transition: all 0.4s ease;
}

.grid-item:hover .grid-icon {
  transform: scale(1.05);
  background: rgba(100, 255, 218, 0.05);
}

.grid-item h3 {
  font-size: 1rem;
  line-height: 1.4;
  margin: 0;
  color: #8892b0;
  font-weight: 500;
  transition: color 0.4s ease;
}

.grid-item:hover h3 {
  color: #ccd6f6;
}

@media (max-width: 1024px) {
  .mission-grid {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .mission-title {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .mission-section {
    padding: 80px 0;
  }

  .visual-grid {
    grid-template-columns: 1fr;
  }

  .mission-stats {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .mission-container {
    padding: 0 24px;
  }

  .stat-highlight {
    font-size: 2rem;
  }

  .grid-item {
    padding: 2rem 1.5rem;
  }
  
  .grid-icon {
    width: 40px;
    height: 40px;
  }
}
