.footer-section {
    background: #0a192f;
    padding: 80px 0 40px;
    position: relative;
    overflow: hidden;
}

/* Background effects */
.footer-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, 
        transparent,
        rgba(100, 255, 218, 0.3),
        transparent
    );
}

.footer-container {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 150px;
}

.footer-grid {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 6rem;
    margin-bottom: 4rem;
    align-items: start;
}

/* Brand section */
.footer-brand {
    max-width: 400px;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer-logo {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
}

.footer-logo .logo-image {
    height: 80px;
    width: auto;
}

.logo-highlight {
    background: linear-gradient(90deg, #64ffda 0%, #00bcd4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.footer-description {
    color: #8892b0;
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
}

/* Social links */
.footer-social {
    display: flex;
    gap: 1.5rem;
    margin-top: 1.5rem;
}

.social-link {
    color: #8892b0;
    transition: color 0.3s ease;
}

.social-link:hover {
    color: #64ffda;
}

.social-link svg {
    width: 24px;
    height: 24px;
}

/* Footer links section */
.footer-links {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.footer-group {
    text-align: left;
    justify-self: center;
    padding: 0 2rem;
}

.footer-group h3 {
    color: #ccd6f6;
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    position: relative;
    text-align: left;
}

.footer-group h3::after {
    content: '';
    position: absolute;
    left: 0;
    transform: none;
    bottom: -0.5rem;
    width: 2rem;
    height: 2px;
    background: linear-gradient(90deg, #64ffda, transparent);
}

.footer-group ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
    align-items: flex-start;
}

.footer-group ul li {
    margin-bottom: 1rem;
}

.footer-group ul a {
    color: #8892b0;
    text-decoration: none;
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: flex-start;
}

.footer-group ul a::before {
    content: '→';
    color: #64ffda;
    opacity: 0;
    transform: translateX(-10px);
    transition: all 0.3s ease;
}

.footer-group ul a:hover {
    color: #64ffda;
    transform: translateX(5px);
}

.footer-group ul a:hover::before {
    opacity: 1;
    transform: translateX(0);
}

/* Footer bottom */
.footer-line {
    height: 1px;
    background: linear-gradient(90deg,
        transparent,
        rgba(100, 255, 218, 0.1),
        transparent
    );
    margin-bottom: 2rem;
}

.footer-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #8892b0;
    font-size: 0.9rem;
}

.footer-meta {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.footer-meta a {
    color: #8892b0;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-meta a:hover {
    color: #64ffda;
}

.divider {
    color: #64ffda;
    opacity: 0.5;
}

/* Responsive design */
@media (max-width: 1200px) {
    .footer-container {
        padding: 0 100px;
    }
}

@media (max-width: 968px) {
    .footer-grid {
        grid-template-columns: 1fr;
        gap: 4rem;
    }

    .footer-brand, .footer-image {
        width: 100%;
    }

    .footer-brand {
        max-width: 100%;
        text-align: center;
    }

    .social-links {
        justify-content: center;
    }

    .footer-links {
        text-align: center;
    }

    .footer-group h3::after {
        left: 50%;
        transform: translateX(-50%);
    }
}

@media (max-width: 768px) {
    .footer-section {
        padding: 60px 0 30px;
    }

    .footer-container {
        padding: 0 24px;
    }

    .footer-links {
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .footer-info {
        flex-direction: column;
        gap: 1rem;
        text-align: center;
    }

    .footer-logo .logo-image {
        height: 65px;
    }

    .footer-social {
        justify-content: center;
    }
}

/* Add these styles for the copyright link */
.copyright {
    color: #8892b0;
    text-align: center;
    font-size: 0.9rem;
}

.parla-link {
    color: #64ffda;
    text-decoration: none;
    transition: color 0.3s ease;
}

.parla-link:hover {
    color: #00bcd4;
}

.footer-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem;
    height: 100%;
    width: auto;
    margin-left: auto;
}

.footer-decoration {
    width: 500px;
    height: 280px;
    object-fit: cover;
    border-radius: 16px;
    border: 1px solid rgba(100, 255, 218, 0.1);
    box-shadow: 0 10px 30px -15px rgba(2, 12, 27, 0.7);
    transition: transform 0.3s ease;
    margin-bottom: 1rem;
}

.footer-decoration:hover {
    transform: translateY(-5px);
}

.footer-address {
    color: #8892b0;
    text-align: center;
    font-size: 1rem;
    margin-top: 0.5rem;
}

@media (max-width: 768px) {
    .footer-decoration {
        width: 100%;
        height: 240px;
    }
}

.footer-logo-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.footer-logo {
    height: 80px;
    width: auto;
}

.admin-link {
    color: #8892b0;
    text-decoration: none;
    transition: color 0.3s ease;
}

.admin-link:hover {
    color: #64ffda;
}

@media (max-width: 768px) {
    .footer-logo {
        height: 65px;
    }
    
    .footer-logo-section {
        align-items: center;
    }
}

.copyright a {
    color: #00bcd4;
    text-decoration: none;
    transition: color 0.3s ease;
}

.copyright a:hover {
    color: #64ffda;
}
