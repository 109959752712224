.about-section {
    padding: 120px 0;
    background: #0a192f;
    position: relative;
    overflow: hidden;
}

.about-container {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 150px;
}

.about-content {
    position: relative;
}

.about-title {
    font-size: 2.5rem;
    color: #ccd6f6;
    margin-bottom: 4rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.about-number {
    color: #64ffda;
    font-family: 'SF Mono', 'Fira Code', monospace;
    font-size: 1.5rem;
    font-weight: normal;
}

.team-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.team-card {
    background: rgba(100, 255, 218, 0.02);
    border: 1px solid rgba(100, 255, 218, 0.1);
    border-radius: 16px;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.team-card:hover {
    transform: translateY(-8px);
    background: rgba(100, 255, 218, 0.05);
    border-color: rgba(100, 255, 218, 0.2);
    box-shadow: 0 20px 40px -20px rgba(100, 255, 218, 0.15);
}

.member-image-container {
    position: relative;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    overflow: hidden;
}

.member-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.member-info {
    padding: 1.5rem;
    text-align: center;
}

.member-name {
    color: #ccd6f6;
    font-size: 1.2rem;
    margin-bottom: 0;
}

.member-role {
    color: #64ffda;
    font-size: 0.9rem;
    opacity: 0.8;
}

.name-with-social {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.name-linkedin-link {
    width: 18px;
    height: 18px;
    color: #64ffda;
    transition: all 0.3s ease;
}

.name-linkedin-link:hover {
    color: #fff;
    transform: translateY(-2px);
}

.name-linkedin-link.disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
}

.name-linkedin-link.disabled:hover {
    transform: none;
    color: #64ffda;
}

@media (max-width: 1200px) {
    .team-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .about-section {
        padding: 80px 0;
    }

    .about-container {
        padding: 0 24px;
    }

    .team-grid {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .about-title {
        font-size: 2rem;
        margin-bottom: 3rem;
    }
}
