.hero-container {
  min-height: 60vh;
  position: relative;
  background: linear-gradient(135deg, #0a192f 0%, #112240 100%);
  overflow: hidden;
}

.neural-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.hero-content-wrapper {
  position: relative;
  z-index: 2;
  min-height: 60vh;
  display: flex;
  align-items: flex-start;
  padding-top: 160px;
}

.hero-content {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 150px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: start;
}

/* Left side styles */
.hero-text-container {
  flex: 1;
  max-width: 600px;
  padding-right: 3rem;
}

/* Right side styles */
.hero-visual-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  padding-right: 150px;
  width: 100%;
}

.circle-canvas {
  width: 350px;
  height: 350px;
  margin-bottom: 3rem;
}

.stats-overlay {
  position: absolute;
  bottom: 0;
  right: 0;
  left: auto;
  width: 100%;
  z-index: 3;
  padding: 0 0 20px 0;
}

.hero-stats {
  width: 100%;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  background: rgba(10, 25, 47, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 1.5rem;
  border: 1px solid rgba(100, 255, 218, 0.1);
  margin-top: 50px;
  margin-bottom: 50px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.stat-item {
  flex: 1;
  text-align: center;
  padding: 1.2rem 1rem;
  background: rgba(100, 255, 218, 0.05);
  border-radius: 12px;
  transition: transform 0.3s ease;
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  min-width: 0;
}

.stat-item:hover {
  transform: translateY(-5px);
  background: rgba(100, 255, 218, 0.08);
}

.stat-icon {
  width: 32px;
  height: 32px;
  color: #64ffda;
}

.stat-icon svg {
  width: 100%;
  height: 100%;
}

.stat-label {
  color: #8892b0;
  font-size: 0.9rem;
  font-family: 'SF Mono', 'Fira Code', monospace;
}

.hero-badge {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: rgba(100, 255, 218, 0.1);
  border: 1px solid rgba(100, 255, 218, 0.2);
  border-radius: 50px;
  margin-bottom: 2rem;
}

.badge-pulse {
  width: 8px;
  height: 8px;
  background: #64ffda;
  border-radius: 50%;
  animation: pulse 2s infinite;
}

.badge-text {
  font-size: 0.9rem;
  color: #64ffda;
  letter-spacing: 1px;
}

.hero-title {
  font-size: 3.5rem;
  line-height: 1.1;
  margin-bottom: 2rem;
}

.title-line {
  overflow: hidden;
  padding: 0.15em 0;
}

.highlight {
  display: block;
  color: #fff;
  transform: translateY(100%);
  animation: slideUp 0.8s forwards;
}

.gradient-text {
  background: linear-gradient(90deg, #64ffda, #00bcd4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transform: translateY(100%);
  animation: slideUp 0.8s 0.4s forwards;
}

.hero-description {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #8892b0;
  margin-bottom: 2.5rem;
  max-width: 600px;
}

.hero-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 0;
}

.primary-button,
.secondary-button {
  position: relative;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.primary-button {
  background: linear-gradient(90deg, #64ffda, #00bcd4);
  color: #0a192f;
}

.button-glow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, rgba(100, 255, 218, 0.4) 0%, transparent 70%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.primary-button:hover .button-glow {
  opacity: 1;
}

.button-icon {
  width: 20px;
  height: 20px;
  fill: currentColor;
}

.stat-value {
  font-size: 2rem;
  font-weight: 700;
  color: #64ffda;
  margin-bottom: 0.5rem;
}

.stat-label {
  color: #8892b0;
  font-size: 0.9rem;
}

@keyframes pulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.5); opacity: 0.5; }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes slideUp {
  to { transform: translateY(0); }
}

@media (max-width: 1630px) {
  .hero-bottom-content {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .hero-stats {
    min-width: unset;
    width: 100%;
  }
}

@media (max-width: 1630px) {
  .hero-content {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 2rem;
    padding: 0 24px;
  }

  .hero-text-container {
    padding-right: 0;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .hero-description {
    max-width: 600px;
    margin: 0 auto 2.5rem;
  }

  .hero-cta {
    justify-content: center;
  }

  .hero-visual-container {
    padding-right: 0;
    margin: 1rem 8rem ;
    width: auto;
  }

  .circle-canvas {
    width: 280px;
    height: 280px;
    margin-bottom: 2rem;
  }

  .hero-stats {
    width: 100%;
    margin: 0;
    padding: 1rem;
  }

  .stat-value {
    font-size: 1.75rem;
  }
}

@media (max-width: 768px) {
  .hero-content {
    padding: 0 24px;
    flex-direction: column;
    text-align: center;
  }

  .hero-content-wrapper {
    padding-top: 100px;
  }

  .hero-visual-container {
    padding-right: 0;
    margin: 2rem 0;
    width: 100%;
  }

  .hero-stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.8rem;
    padding: 1rem;
    width: calc(100% - 2rem);
    margin: 0 auto;
  }

  .stat-item {
    padding: 1rem 0rem;
    width: 100%;
  }

  .stat-icon {
    width: 20px;
    height: 20px;
  }

  .stat-label {
    font-size: 0.8rem;
    word-break: break-word;
  }

  .hero-cta {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .cta-button {
    width: 100%;
    justify-content: center;
  }

  .neural-canvas {
    display: none;
  }

  .hero-container {
    background: #0a192f;
  }
}

@media (max-width: 480px) {
  .hero-stats {
    grid-template-columns: 1fr;
    width: calc(100% - 2rem);
  }

  .hero-title {
    font-size: 2rem;
  }

  .hero-badge {
    font-size: 0.8rem;
    padding: 6px 12px;
  }

  .circle-canvas {
    width: 240px;
    height: 240px;
  }

  .hero-description {
    font-size: 0.9rem;
    padding: 0 1rem;
  }
}

/* Tilføj mellemstørrelse for tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  .hero-stats {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }

  .hero-content {
    padding: 0 48px;
  }

  .hero-visual-container {
    padding-right: 48px;
  }
}
