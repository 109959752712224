.dashboard-container {
    min-height: 100vh;
    background: #0a192f;
    color: #ccd6f6;
}

.dashboard-header {
    background: rgba(10, 25, 47, 0.95);
    border-bottom: 1px solid rgba(100, 255, 218, 0.1);
    padding: 1.2rem 0;
    position: sticky;
    top: 0;
    z-index: 100;
    backdrop-filter: blur(10px);
}

.header-content {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-left {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.dashboard-logo {
    height: 45px;
    transition: transform 0.3s ease;
}

.dashboard-logo:hover {
    transform: translateY(-2px);
}

.home-link {
    color: #8892b0;
    text-decoration: none;
    font-size: 0.9rem;
    font-family: 'SF Mono', 'Fira Code', monospace;
    transition: all 0.3s ease;
    padding: 0.5rem 1rem;
    border-radius: 4px;
}

.home-link:hover {
    color: #64ffda;
    background: rgba(100, 255, 218, 0.05);
}

.logout-button {
    padding: 0.8rem 1.5rem;
    background: transparent;
    border: 1px solid #64ffda;
    color: #64ffda;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 0.9rem;
    font-family: 'SF Mono', 'Fira Code', monospace;
}

.logout-button:hover {
    background: rgba(100, 255, 218, 0.1);
    transform: translateY(-2px);
}

.dashboard-content {
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem 24px;
}

.add-event-section {
    background: rgba(100, 255, 218, 0.02);
    border: 1px solid rgba(100, 255, 218, 0.1);
    border-radius: 16px;
    padding: 3rem;
    margin-bottom: 4rem;
    box-shadow: 0 10px 30px -15px rgba(2, 12, 27, 0.7);
    transition: transform 0.3s ease;
}

.add-event-section h2 {
    color: #ccd6f6;
    margin-bottom: 2rem;
    font-size: 1.8rem;
    position: relative;
    display: flex;
    align-items: center;
}

.add-event-section h2::before {
    content: '01.';
    font-family: 'SF Mono', 'Fira Code', monospace;
    color: #64ffda;
    font-size: 1.2rem;
    margin-right: 1rem;
}

.event-form {
    margin-top: 2rem;
}

.form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.5rem;
    position: relative;
}

.form-group.full-width {
    grid-column: 1 / -1;
    margin-bottom: 2rem;
}

.form-group label {
    color: #64ffda;
    font-size: 0.9rem;
    font-family: 'SF Mono', 'Fira Code', monospace;
    display: block;
    margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea {
    padding: 1.2rem;
    background: rgba(100, 255, 218, 0.02);
    border: 1px solid rgba(100, 255, 218, 0.1);
    border-radius: 8px;
    color: #ccd6f6;
    font-size: 1rem;
    transition: all 0.3s ease;
    min-height: 3.5rem;
    width: 100%;
    box-sizing: border-box;
}

.form-group textarea {
    min-height: 180px;
    resize: vertical;
    font-family: inherit;
    line-height: 1.6;
    padding: 1rem;
    margin-bottom: 1rem;
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-color: #64ffda;
    background: rgba(100, 255, 218, 0.05);
}

.submit-button {
    margin-top: 2rem;
    padding: 1rem 2rem;
    background: transparent;
    border: 1px solid #64ffda;
    color: #64ffda;
    border-radius: 4px;
    font-size: 0.9rem;
    font-family: 'SF Mono', 'Fira Code', monospace;
    cursor: pointer;
    transition: all 0.3s ease;
    width: auto;
}

.submit-button:hover:not(:disabled) {
    background: rgba(100, 255, 218, 0.1);
    transform: translateY(-2px);
}

.submit-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.events-list-section {
    margin-top: 4rem;
}

.events-list-section h2 {
    color: #ccd6f6;
    margin-bottom: 2rem;
    font-size: 1.8rem;
    position: relative;
    display: flex;
    align-items: center;
}

.events-list-section h2::before {
    content: '02.';
    font-family: 'SF Mono', 'Fira Code', monospace;
    color: #64ffda;
    font-size: 1.2rem;
    margin-right: 1rem;
}

.events-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 2rem;
    margin-top: 2rem;
}

.event-card {
    background: rgba(100, 255, 218, 0.02);
    border: 1px solid rgba(100, 255, 218, 0.1);
    border-radius: 16px;
    padding: 2rem;
    position: relative;
    transition: all 0.3s ease;
    margin-bottom: 1rem;
}

.event-date {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
    font-family: 'SF Mono', 'Fira Code', monospace;
    color: #64ffda;
}

.event-name {
    color: #ccd6f6;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.event-description {
    color: #8892b0;
    line-height: 1.6;
    margin-bottom: 1rem;
}

.event-meta {
    display: flex;
    justify-content: space-between;
    color: #64ffda;
    font-family: 'SF Mono', 'Fira Code', monospace;
    font-size: 0.9rem;
    margin-top: 1.5rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(100, 255, 218, 0.1);
}

.delete-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0.5rem 1rem;
    background: transparent;
    border: 1px solid #ff6347;
    color: #ff6347;
    border-radius: 4px;
    font-family: 'SF Mono', 'Fira Code', monospace;
    font-size: 0.8rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.delete-button:hover {
    background: rgba(255, 99, 71, 0.1);
}

.success-message,
.error-message {
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    text-align: center;
    font-family: 'SF Mono', 'Fira Code', monospace;
    animation: fadeIn 0.3s ease;
}

@media (max-width: 768px) {
    .dashboard-content {
        padding: 1.5rem 24px;
    }

    .add-event-section {
        padding: 2rem;
    }

    .form-grid {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .header-left {
        gap: 1rem;
    }

    .events-grid {
        grid-template-columns: 1fr;
    }

    .dashboard-logo {
        height: 40px;
    }

    .form-group {
        margin-bottom: 1.2rem;
    }

    .event-card {
        padding: 1.5rem;
    }
}

.image-upload-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.image-input {
    display: none;
}

.image-upload-label {
    padding: 1rem 2rem;
    background: transparent;
    border: 1px dashed #64ffda;
    color: #64ffda;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s ease;
}

.image-upload-label:hover {
    background: rgba(100, 255, 218, 0.05);
}

.image-preview {
    width: 100%;
    max-height: 300px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid rgba(100, 255, 218, 0.1);
}

.image-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.event-card .event-image {
    width: 100%;
    height: 200px;
    border-radius: 8px;
    margin-bottom: 1rem;
    overflow: hidden;
}

.event-card .event-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.event-card:hover .event-image img {
    transform: scale(1.05);
}

.button-group {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
}

.edit-button {
    padding: 0.5rem 1rem;
    background: rgba(100, 255, 218, 0.1);
    border: 1px solid #64ffda;
    color: #64ffda;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.edit-button:hover {
    background: rgba(100, 255, 218, 0.2);
}

.cancel-button {
    padding: 1rem 2rem;
    background: transparent;
    border: 1px solid #ff6b6b;
    color: #ff6b6b;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.cancel-button:hover {
    background: rgba(255, 107, 107, 0.1);
}

.event-actions {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(100, 255, 218, 0.1);
}

.success-message {
    background: rgba(100, 255, 218, 0.1);
    border: 1px solid rgba(100, 255, 218, 0.3);
    color: #64ffda;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1.5rem;
}

.event-link {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    word-break: break-all;
}

.event-link .link-label {
    color: #64ffda;
    margin-right: 0.5rem;
}

.event-link a {
    color: #8892b0;
    text-decoration: none;
    transition: color 0.3s ease;
}

.event-link a:hover {
    color: #64ffda;
} 