.App {
  min-height: 100vh;
  width: 100%;
}

/* Add these styles to maintain consistent spacing */
.App section {
    padding: 2rem 0;
}

/* If you need to adjust spacing specifically around the newsletter */
#contact + .newsletter-container {
    margin-top: 0;  /* Adjust the top margin since it follows the contact section */
}
