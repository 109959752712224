.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background: transparent;
}

.header.scrolled {
  background: rgba(10, 25, 47, 0.85);
  backdrop-filter: blur(10px);
  box-shadow: 0 10px 30px -10px rgba(2, 12, 27, 0.7);
}

.header-container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 30px 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: padding 0.3s ease;
}

.header.scrolled .header-container {
  padding: 20px 150px;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 70px;
  width: auto;
  transition: transform 0.3s ease;
}

.logo:hover .logo-image {
  transform: translateY(-2px);
}

.nav-menu {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.nav-links {
  display: flex;
  gap: 3rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links a {
  color: #8892b0;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
  position: relative;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.nav-number {
  color: #64ffda;
  font-size: 0.8rem;
  font-family: monospace;
}

.nav-links a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(90deg, #64ffda 0%, #00bcd4 100%);
  transition: width 0.3s ease;
}

.nav-links a:hover {
  color: #ffffff;
}

.nav-links a:hover::after {
  width: 100%;
}

.nav-button {
  padding: 0.8rem 1.5rem;
  border: 2px solid #64ffda;
  border-radius: 4px;
  background: transparent;
  color: #64ffda;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.nav-button:hover {
  background: rgba(100, 255, 218, 0.1);
  transform: translateY(-2px);
}

.menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
}

.menu-toggle span {
  width: 100%;
  height: 2px;
  background: #64ffda;
  transition: all 0.3s ease;
}

@media (max-width: 1400px) {
  .menu-toggle {
    display: flex;
  }

  .nav-menu {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 100%;
    background: rgba(10, 25, 47, 0.98);
    flex-direction: column;
    justify-content: center;
    transition: all 0.3s ease;
    backdrop-filter: blur(10px);
  }

  .nav-menu.active {
    right: 0;
  }

  .nav-links {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .nav-links a {
    font-size: 1.1rem;
  }

  .menu-toggle.active span:first-child {
    transform: rotate(45deg) translate(6px, 6px);
  }

  .menu-toggle.active span:nth-child(2) {
    opacity: 0;
  }

  .menu-toggle.active span:last-child {
    transform: rotate(-45deg) translate(6px, -6px);
  }

  .nav-button {
    margin-top: 2rem;
  }



  .logo-image {
    height: 55px;
  }
}

@media (max-width: 768px) {
  .header-container {
    padding: 30px 24px;
  }

  .header.scrolled .header-container {
    padding: 20px 24px;
  }
}

@media (max-width: 480px) {
  .logo {
    font-size: 1.5rem;
  }

  .header {
    padding: 1rem 0;
  }

  .header.scrolled {
    padding: 0.8rem 0;
  }
}

.cta-button.primary {
  background: rgba(100, 255, 218, 0.1);
  border: 1px solid rgba(100, 255, 218, 0.3);
  color: #64ffda;
}

.cta-button.primary:hover {
  background: rgba(100, 255, 218, 0.2);
}

.button-icon {
  width: 20px;
  height: 20px;
  fill: currentColor;
}
