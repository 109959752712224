.login-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0a192f;
    padding: 20px;
}

.login-box {
    background: rgba(100, 255, 218, 0.02);
    border: 1px solid rgba(100, 255, 218, 0.1);
    border-radius: 16px;
    padding: 2.5rem;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 10px 30px -15px rgba(2, 12, 27, 0.7);
}

.login-header {
    text-align: center;
    margin-bottom: 2rem;
}

.login-logo {
    height: 80px;
    margin-bottom: 1rem;
}

.login-header h2 {
    color: #ccd6f6;
    font-size: 1.8rem;
    margin: 0;
}

.login-subtitle {
    color: #8892b0;
    font-size: 0.9rem;
    margin: 0.5rem 0 0;
}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.form-group input {
    width: 100%;
    padding: 1rem 1.2rem;
    background: rgba(100, 255, 218, 0.02);
    border: 1px solid rgba(100, 255, 218, 0.1);
    border-radius: 8px;
    color: #ccd6f6;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.form-group input:focus {
    outline: none;
    border-color: rgba(100, 255, 218, 0.3);
    background: rgba(100, 255, 218, 0.05);
    box-shadow: 0 0 0 4px rgba(100, 255, 218, 0.1);
}

.login-button {
    padding: 1rem;
    background: linear-gradient(135deg, rgba(100, 255, 218, 0.9), rgba(0, 188, 212, 0.9));
    border: none;
    border-radius: 8px;
    color: #0a192f;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.login-button:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 7px 20px -3px rgba(100, 255, 218, 0.25);
}

.login-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.error-message {
    background: rgba(255, 99, 71, 0.1);
    border: 1px solid rgba(255, 99, 71, 0.3);
    color: #ff6347;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    text-align: center;
}

.forgot-password-button {
    margin-top: 1rem;
    background: transparent;
    border: none;
    color: #64ffda;
    cursor: pointer;
    font-size: 0.9rem;
    opacity: 0.8;
    transition: all 0.3s ease;
    padding: 0.5rem 1rem;
    font-family: 'SF Mono', 'Fira Code', monospace;
}

.forgot-password-button:hover {
    opacity: 1;
    text-decoration: underline;
}

.success-message {
    background: rgba(100, 255, 218, 0.1);
    border: 1px solid rgba(100, 255, 218, 0.3);
    color: #64ffda;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    text-align: center;
    font-family: 'SF Mono', 'Fira Code', monospace;
}

@media (max-width: 480px) {
    .login-box {
        padding: 2rem;
    }
}

.button-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.go-back-button {
    display: block;
    text-align: center;
    margin-top: 2rem;
    color: #8892b0;
    text-decoration: none;
    font-size: 0.9rem;
    font-family: 'SF Mono', 'Fira Code', monospace;
    transition: all 0.3s ease;
    padding: 0.5rem 1rem;
    border-radius: 4px;
}

.go-back-button:hover {
    color: #64ffda;
    background: rgba(100, 255, 218, 0.05);
}
