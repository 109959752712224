.event-section {
  padding: 120px 0;
  background: #0a192f;
  position: relative;
}

.event-container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 150px;
}

.event-content {
  position: relative;
}

.event-title {
  font-size: 2.5rem;
  color: #ccd6f6;
  margin-bottom: 4rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.event-number {
  color: #64ffda;
  font-family: 'SF Mono', 'Fira Code', monospace;
  font-size: 1.5rem;
  font-weight: normal;
}

.event-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.event-card {
  background: rgba(100, 255, 218, 0.02);
  border: 1px solid rgba(100, 255, 218, 0.1);
  border-radius: 16px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
}

.event-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, 
    transparent,
    rgba(100, 255, 218, 0.3),
    transparent
  );
}

.event-card:hover {
  transform: translateY(-8px);
  background: rgba(100, 255, 218, 0.05);
  border-color: rgba(100, 255, 218, 0.2);
  box-shadow: 0 20px 40px -20px rgba(100, 255, 218, 0.15);
}

.event-date {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 1.2rem;
  background: rgba(100, 255, 218, 0.05);
  border-radius: 12px;
  width: fit-content;
}

.date-number {
  font-size: 2.2rem;
  font-weight: 700;
  color: #64ffda;
  line-height: 1;
}

.date-month {
  font-size: 1rem;
  color: #8892b0;
  margin-top: 0.4rem;
  font-weight: 500;
}

.date-year {
  font-size: 0.9rem;
  color: #8892b0;
  margin-top: 0.2rem;
  font-weight: 500;
}

.event-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.event-name {
  font-size: 1.4rem;
  color: #ccd6f6;
  margin-bottom: 1rem;
  line-height: 1.4;
  font-weight: 600;
}

.event-description {
  color: #8892b0;
  font-size: 1rem;
  line-height: 1.7;
  margin-bottom: 1.5rem;
  flex: 1;
}

.event-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  font-size: 0.9rem;
  color: #64ffda;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(100, 255, 218, 0.1);
}

.event-time, .event-location {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  opacity: 0.9;
  min-width: 100px;
}

.event-time::before {
  content: '🕒';
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  font-size: 1.1rem;
}

.event-location::before {
  content: '📍';
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  font-size: 1.1rem;
}

.event-link-button {
  padding: 0.5rem 1.2rem;
  background: rgba(100, 255, 218, 0.08);
  border: 1px solid rgba(100, 255, 218, 0.2);
  border-radius: 6px;
  color: #64ffda;
  text-decoration: none;
  font-size: 0.85rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  min-width: 100px;
}

.event-link-button:hover {
  background: rgba(100, 255, 218, 0.15);
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(100, 255, 218, 0.15);
}

.event-link-button svg {
  width: 12px;
  height: 12px;
  transition: transform 0.2s ease;
}

.event-link-button:hover svg {
  transform: translateY(-1px);
}

@media (max-width: 1400px) {
  .event-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1024px) {
  .event-container {
    padding: 0 100px;
  }
}

@media (max-width: 768px) {
  .event-section {
    padding: 80px 0;
  }

  .event-container {
    padding: 0 24px;
  }

  .event-grid {
    grid-template-columns: 1fr;
  }

  .event-title {
    font-size: 2rem;
    margin-bottom: 3rem;
  }

  .event-card {
    padding: 1.5rem;
  }

  .event-name {
    font-size: 1.2rem;
  }

  .event-meta {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .event-time, .event-location, .event-link-button {
    width: 100%;
    justify-content: flex-start;
  }
}

.no-events {
    grid-column: 1 / -1;
    text-align: center;
    padding: 3rem;
    background: rgba(100, 255, 218, 0.02);
    border: 1px solid rgba(100, 255, 218, 0.1);
    border-radius: 16px;
    color: #8892b0;
}

.no-events p {
    font-size: 1.1rem;
    margin: 0;
}

.event-image {
    width: 100%;
    height: 200px;
    border-radius: 8px;
    margin-bottom: 1rem;
    overflow: hidden;
}

.event-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.event-card:hover .event-image img {
    transform: scale(1.05);
}

.event-cta-button {
    display: inline-flex;
    align-items: center;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background: rgba(100, 255, 218, 0.1);
    border: 1px solid rgba(100, 255, 218, 0.3);
    border-radius: 4px;
    color: #64ffda;
    text-decoration: none;
    font-size: 0.9rem;
    transition: all 0.3s ease;
}

.event-cta-button:hover {
    background: rgba(100, 255, 218, 0.2);
    transform: translateY(-2px);
}
