.newsletter-section {
    padding: 120px 0;
    background: #0a192f;
    position: relative;
    overflow: hidden;
}

/* Enhanced background pattern */
.newsletter-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
        radial-gradient(circle at 20% 30%, rgba(100, 255, 218, 0.03) 0%, transparent 70%),
        radial-gradient(circle at 80% 70%, rgba(0, 188, 212, 0.03) 0%, transparent 70%);
    pointer-events: none;
}

.newsletter-container {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 150px;
    position: relative;
}

.newsletter-content {
    position: relative;
}

.newsletter-title {
    font-size: 2.5rem;
    color: #ccd6f6;
    margin-bottom: 4rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.newsletter-number {
    color: #64ffda;
    font-family: 'SF Mono', 'Fira Code', monospace;
    font-size: 1.5rem;
    font-weight: normal;
}

.newsletter-grid {
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    gap: 6rem;
    align-items: center;
}

.newsletter-description {
    color: #8892b0;
    font-size: 1.2rem;
    line-height: 1.7;
    margin-bottom: 0;
    max-width: 500px;
}

/* Enhanced form container */
.newsletter-form-container {
    background: rgba(100, 255, 218, 0.02);
    border: 1px solid rgba(100, 255, 218, 0.1);
    border-radius: 16px;
    padding: 3rem;
    position: relative;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 10px 30px -10px rgba(2, 12, 27, 0.7);
}

/* Glowing border effect */
.newsletter-form-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, 
        transparent,
        rgba(100, 255, 218, 0.3),
        transparent
    );
}

.newsletter-form-container:hover {
    background: rgba(100, 255, 218, 0.05);
    border-color: rgba(100, 255, 218, 0.2);
    transform: translateY(-5px);
}

.form-header {
    margin-bottom: 2rem;
}

.form-badge {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background: rgba(100, 255, 218, 0.1);
    border: 1px solid rgba(100, 255, 218, 0.2);
    border-radius: 50px;
}

.badge-pulse {
    width: 8px;
    height: 8px;
    background: #64ffda;
    border-radius: 50%;
    animation: pulse 2s infinite;
}

.badge-text {
    color: #64ffda;
    font-size: 0.9rem;
    letter-spacing: 1px;
}

.newsletter-form {
    display: flex;
    gap: 1rem;
}

.form-group {
    flex: 1;
    position: relative;
}

.input-field {
    width: 100%;
    padding: 1rem 1.5rem;
    background: rgba(100, 255, 218, 0.02);
    border: 1px solid rgba(100, 255, 218, 0.1);
    border-radius: 8px;
    color: #ccd6f6;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.input-field:focus {
    outline: none;
    border-color: rgba(100, 255, 218, 0.3);
    background: rgba(100, 255, 218, 0.05);
    box-shadow: 0 0 0 4px rgba(100, 255, 218, 0.1);
}

.input-field::placeholder {
    color: #8892b0;
}

@keyframes pulse {
    0% { transform: scale(1); opacity: 1; }
    50% { transform: scale(1.5); opacity: 0.5; }
    100% { transform: scale(1); opacity: 1; }
}

@media (max-width: 1200px) {
    .newsletter-grid {
        grid-template-columns: 1fr;
        gap: 4rem;
    }
    
    .newsletter-description {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}

@media (max-width: 768px) {
    .newsletter-section {
        padding: 80px 0;
    }

    .newsletter-container {
        padding: 0 24px;
    }

    .newsletter-title {
        font-size: 2rem;
        margin-bottom: 3rem;
    }

    .newsletter-form-container {
        padding: 2rem;
    }

    .newsletter-form {
        flex-direction: column;
    }

    .newsletter-form .cta-button {
        width: 100%;
    }

    .feature-item:hover {
        transform: none;
    }
}

.cta-button.loading {
    opacity: 0.7;
    cursor: not-allowed;
}

.cta-button.success {
    background: #64ffda;
    color: #0a192f;
}

.cta-button.error {
    background: #ff6464;
    color: #fff;
}

.form-error {
    color: #ff6464;
    font-size: 0.9rem;
    margin-top: 1rem;
    text-align: center;
}

.input-field:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}
